<template>
  <!-- 新增文章专栏 -->
  <div class="container">
    <div class="policyCompilationTitle">电子政策汇编系统</div>
    <el-breadcrumb separator-class="el-icon-arrow-right" class="navbar">
      <el-breadcrumb-item :to="{ path: '/policyCompilation' }">
        <el-button type="primary" size="small" class="smallBtn">返回</el-button>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <el-button type="text" size="small" style="color: #555;font-size: 14px;">新增政策素材</el-button>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <el-row :gutter="20">
      <el-col :span="12" style="padding:0">
        <div class="item">
          <label class="label required">政策标题：</label>
          <div class="formControl">
            <el-input v-model="title" size="mini" maxlength="200"></el-input>
          </div>
        </div>

        <div class="item">
          <label class="label required">一句话描述：</label>
          <div class="formControl">
            <el-input v-model="description" size="mini" maxlength="500"></el-input>
          </div>
        </div>

        <div class="item" style="align-items: flex-start;">
          <label class="label required">发布单位：</label>
          <div class="itemBorder formControl">
            <div class="controls">
              <label for class="controlslabel">全称：</label>
              <el-input v-model="publisher_full_name" size="mini" maxlength="100"></el-input>
            </div>
            <div class="controls">
              <label for class="controlslabel">简称：</label>
              <el-input v-model="publisher_simple_name" size="mini" maxlength="100"></el-input>
            </div>
          </div>
        </div>

        <div class="item">
          <label for class="label required">适用地域：</label>
          <div class="formControl">
            <el-radio-group v-model="apply_to_area_type">
              <el-radio
                :label="item.id"
                v-for="item in areaType"
                :key="item.id"
                @change="diyuChange"
              >{{ item.title }}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="item" v-if="apply_to_area_type == 2">
          <label for class="label"></label>
          <div class="formControl">
            <el-cascader
              :options="area"
              :props="props"
              size="mini"
              :show-all-levels="false"
              v-model="areas"
            ></el-cascader>
          </div>
        </div>

        <div class="item" v-if="apply_to_area_type == 3">
          <label for class="label"></label>
          <div class="formControl">
            <el-cascader :options="yuanqu" :props="props2" size="mini" v-model="yq"></el-cascader>
          </div>
        </div>

        <div class="item guimo" style="align-items: flex-start;">
          <label for class="label required">规模：</label>
          <div class="itemBorder formControl">
            <div style="display: flex;">
              <div class="controls">
                <label for class="controlslabel">规模：</label>
                <el-select v-model="scale_id" placeholder="规模编号" size="mini" style="width: 175px;" multiple collapse-tags>
                  <el-option
                    v-for="item in guimo"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="controls">
                <label for class="controlslabel">从业人数：</label>
                <el-input
                  type="number"
                  v-model="scale_engage_number"
                  placeholder="从业人数"
                  size="mini"
                  style="width: 90px;"
                ></el-input>
              </div>
            </div>
            <div style="display: flex;">
              <div class="controls">
                <label for class="controlslabel">营业收入：</label>
                <el-input
                  type="number"
                  v-model="scale_revenue"
                  placeholder="营业收入"
                  size="mini"
                  style="width: 133px;"
                >
                  <template slot="append">万元</template>
                </el-input>
              </div>
              <div class="controls">
                <label for class="controlslabel">资产总额：</label>
                <el-input
                  type="number"
                  v-model="total_assets"
                  placeholder="资产总额"
                  size="mini"
                  style="width: 133px;"
                >
                  <template slot="append">万元</template>
                </el-input>
              </div>               
            </div>
            <div style="display: flex;">
              <div class="controls">
                <label for class="controlslabel">行业：</label>
                <el-select
                  v-model="scale_industry_id"
                  placeholder="行业编号"
                  size="mini"
                  style="width: 220px;"
                  multiple 
                  collapse-tags
                  @change="hyChange"
                >
                  <el-option
                    v-for="item in hangye"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>              
            </div>
          </div>
        </div>

        <div class="item" style="align-items: flex-start;">
          <label for class="label required">类型：</label>
          <div class="itemBorder formControl" style="display: flex;">
            <el-checkbox-group v-model="types">
              <el-checkbox
                style="margin:8px"
                v-for="item in materialType"
                :key="item.id"
                :label="item.id"
              >{{ item.title }}</el-checkbox>
              <el-button size="mini" style="margin-left: 8px;" @click="customType">自定义</el-button>
            </el-checkbox-group>
          </div>
        </div>

        <div class="item">
          <label for class="label required">文章类型：</label>
          <div class="formControl">
            <el-select v-model="booksType" placeholder="请选择" size="mini" @change="changeArtical">
              <!-- <el-option value="插入链接">插入链接</el-option> -->
              <el-option value="自定义">自定义</el-option>
            </el-select>
          </div>
        </div>
        <div class="item" v-if="booksTypeActive">
          <label class="label"></label>
          <div class="formControl">
            <el-input v-model="article_link_url" placeholder="请输入链接地址" size="mini" maxlength="100"></el-input>
          </div>
        </div>

        <div class="item" style="align-items: flex-start;" v-else>
          <label for class="label"></label>
          <div class="formControl" style="height: 400px;">
            <Editor @getContent="getEditorContent" :value="content"></Editor>
          </div>
        </div>

        <div class="item" style="align-items: flex-start;">
          <label for class="label"></label>
          <div class="formControl">
            <el-button class="btn-primary" @click="fabu">发布</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="12" style="padding-left:20px">
        <div class="item" style="align-items: flex-start;">
          <label for class="label required">政策主图：</label>
          <div class="formControl" style="display: flex;align-items: flex-end;">
            <el-upload
              class="avatar-uploader"
              accept="image/jpeg, image/jpg, image/png"
              :action="action"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="handleChange"
            >
              <img v-if="img" :src="img" class="avatar" />
              <div class="noAvatar" v-else>
                <i class="el-icon-upload avatar-uploader-icon"></i>
                <span>上传图片</span>
              </div>
            </el-upload>
            <div class="warn">
               <div
                  style="color: red; margin-left: 15px;margin-bottom: 15px;font-size: 14px;"
                >建议上传大小为1：1的图片</div>
                <div
                  style="color: red; margin-left: 15px;margin-bottom: 15px;font-size: 14px;"
                >图片格式支持：jpg、jepg、png，大小不超过4M</div>
            </div>
           </div>
        </div>
        <div class="item lyClass">
          <label for class="label required">领域(行业)：</label>
          <div class="formControl">
            <el-checkbox-group v-model="industry_scope" @change="industryScopeChange">
              <el-checkbox 
                :label="item.id"
                v-for="item in industryScopeType"
                :key="item.id"
              >{{ item.title }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="lyClass-select">
            <div class="formControl">
              <el-cascader
                :options="lingyuCT"
                :props="props3"
                size="mini"
                :show-all-levels="false"
                v-model="industry_scope_array_ct"
                :disabled='lingyuCT_Dis'
                multiple 
                collapse-tags                
              ></el-cascader>
            </div>
            <div class="formControl">
              <el-cascader
                :options="lingyuGM"
                :props="props3"
                size="mini"
                :show-all-levels="false"
                v-model="industry_scope_array_gm"
                :disabled='lingyuGM_Dis'
                multiple 
                collapse-tags                
              ></el-cascader>
            </div>          
          </div>
        </div>
        <div class="item" style="align-items: flex-start;">
          <label for class="label required">体系：</label>
          <div class="itemBorder formControl" style="display: flex;">
            <el-checkbox-group v-model="systems">
              <el-checkbox
                style="margin:8px"
                v-for="item in tixi"
                :key="item.id"
                :label="item.id"
              >{{ item.title }}</el-checkbox>
              <el-button size="mini" style="margin-left: 8px;" @click="customSystems">自定义</el-button>
            </el-checkbox-group>
          </div>
        </div>

        <div class="item">
          <label for class="label required">政策状态：</label>
          <div class="formControl">
            <el-radio-group v-model="policy_status">
              <el-radio
                :label="item.id"
                v-for="item in policyStatusType"
                :key="item.id"
              >{{ item.title }}</el-radio>
            </el-radio-group>
          </div>
        </div>

        <div class="item">
          <label for class="label">政策有效日期：</label>
          <div class="formControl">
            <el-date-picker
              size="mini"
              v-model="policy_date_long"
              type="date"
              v-if="policy_checked"
            ></el-date-picker>
            <el-date-picker
              size="mini"
              v-model="policy_date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              v-else
            ></el-date-picker>
            <el-checkbox v-model="policy_checked" style="margin-left: 10px">长期</el-checkbox>
          </div>
        </div>
        <div class="item" style="align-items: flex-start;">
          <label for class="label">附件上传：</label>
          <div class="formControl">
            <el-upload
              class="upload"
              :limit="100"
              drag
              :action="action"
              accept=".doc, .docx, .pdf, .xls, .ppt, xlsx"
              :on-remove="handleRemove"
              :on-success="handleOnChange"
              :file-list="fileList"
            >
              <i class="el-icon-upload fijian-icon-upload"></i>
              <div class="el-upload__text" style="font-size: 14px;">点击或将文件拖拽到这里上传</div>
              <div class="el-upload__tip" slot="tip">支持扩展名：.doc .docx .pdf .excel .ppt</div>
            </el-upload>
          </div>
        </div>
        <div class="item">
          <label for class="label"></label>
          <div class="formControl">
            <div>本政策可为企业带来的主要扶持帮助的内容包括（多选）</div>
            <div style="margin-top:5px ">
              <el-select size="mini" multiple style="width:90%" v-model="demands" placeholder="请选择">
                <el-option
                  v-for="item in demandsLable"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div>
              <el-card class="box-card" style="width:90%">
                <div class="clearfix" style="text-align:right">
                  <el-button type="primary" size="small" @click="orderDemands" class="addBtn">+自定义添加</el-button>
                </div>
                <div class="text item">
                  <el-tag
                    style="margin:0 2px"
                    v-for="(item,i) in threeLable"
                    :key="i"
                  >{{item.title }}</el-tag>
                </div>
              </el-card>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="添加标签" :visible.sync="orderDialog" width="30%" center top="15%" class="addName">
      <el-input v-model="orderLabel" autocomplete="off"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="orderDialog = false">取 消</el-button>
        <el-button type="primary" @click="orderSure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Editor from "@/components/Editor";
import qs from "qs";
import { warning, success } from "@/utils/notification";
export default {
  data() {
    return {
      title: "", //标题
      description: "", //描述
      publisher_full_name: "", //称全成
      publisher_simple_name: "", //简称
      apply_to_area_type: 1, //地域:1全国,2其他省市,3园区
      apply_to_area_type_array: [], //apply_to_area_type:省市区数组，3:园区数组，1:不传
      scale_id: [], //规模编号
      scale_industry_id: [], //行业编号
      scale_engage_number: "", //从业人数
      scale_revenue: "", //	营业收入
      total_assets: "", //资产总额
      types: [], //类型
      article_link_url: "", //文章类型链接地址，为空则表示本地输入，否则为外链
      content: "", //富文本
      img: "",
      industry_scope: [1], //1创投2国民经济
      industry_scope_array_ct: "", //industry_scope： 1:创投数组，2:国民经济数组
      industry_scope_array_gm: "", //industry_scope： 1:创投数组，2:国民经济数组
      systems: [], //体系
      policy_status: 1, //政策状态 1即将生效2生效中3已过期4延长期
      policy_date: "", //时间
      policy_date_long: "", //长期时间
      attachments: [], //附件
      demandsLable: [], //需求标签
      orderDialog: false, //自定义添加弹窗
      orderForm: {}, //标签表单
      orderLabel: "", //输入标签
      threeLable: [], //默认展示3个标签
      demands: [], //政策需求id数组
      policy_checked: false,//政策长期有效
      areaType: [
        {
          id: 1,
          title: "全国"
        },
        {
          id: 2,
          title: "其他省市"
        },
        {
          id: 3,
          title: "园区"
        }
      ],
      industryScopeType: [
        {
          id: 1,
          title: "创投行业分类"
        },
        {
          id: 2,
          title: "国民经济行业分类"
        }
      ],
      policyStatusType: [
        {
          id: 1,
          title: "即将生效"
        },
        {
          id: 2,
          title: "生效中"
        },
        {
          id: 3,
          title: "已过期"
        },
        {
          id: 4,
          title: "延长期"
        }
      ],
      materialType: [],
      tixi: [],
      booksType: "自定义",
      booksTypeActive: false,
      fileList: [],
      guimo: [], //规模
      hangye: [], //行业
      allScaleId: [],
      congye: [], //从业人数
      yingye: [], //营业收入
      zichan: [], //资产总额
      lingyuCT: [], //领域
      lingyuCT_Dis: false,
      lingyuGM: [], //领域
      lingyuGM_Dis: true,
      action: this.URL.upLoad,
      id: "",
      cityID: "",
      area: [],
      yuanqu: [],
      areas: [],
      yq: [],
      props2: {
        multiple: true,
        value: "id",
        label: "name"
      },
      props: {
        multiple: true,
        checkStrictly: true,
        value: "id",
        label: "name"
      },
      props3: {
        multiple: true,
        checkStrictly: true,
        value: "id",
        label: "title"
      }
    };
  },
  components: {
    Editor
  },
  created() {
    this.categoryDemands();
  },
  mounted() {
    var url = "";
    this.getGuimoData();
    this.getHangyeData();
    this.getCongyeData();
    this.getyingyeData();
    this.getZichanData();
    this.getLingyuData();
    this.getTixiData();
    if (JSON.parse(localStorage.getItem("area"))) {
      this.area = JSON.parse(localStorage.getItem("area"));
    } else {
      this.getgetArea();
    }

    this.getgetOrgins();
    this.getMaterialTypeData();
    this.id = this.$route.query.id;
    if (typeof this.id != "undefined") {
      this.booksTypeActive = true;
      this.getdetailsData();
    }
  },
  methods: {
    //获取政策-详情数据
    getdetailsData() {
      this.$http.get(this.URL.adminUrl.delPolicyArticle + this.id).then(res => {
        let result = res.data.data;
        this.title = result.title;
        this.demands = result.demands;
        result.demands.length > 0 &&
          (this.demands = result.demands.map(item => item.id));
        this.description = result.description;
        this.publisher_full_name = result.publisher_full_name;
        this.publisher_simple_name = result.publisher_simple_name;
        this.apply_to_area_type = result.apply_to_area_type;
        if (result.apply_to_area_type == 2) {
          let arr3 = [],
            arr4 = [];
          result.areaOrorgan.map(item => {
            arr4 = [];
            item.map(item2 => {
              if (arr4.length) {
                arr4.push(item2.id);
              } else {
                let len = arr4.indexOf(item2.id);
                if (len > -1) {
                  arr4.splice(len, 1);
                } else {
                  arr4.push(item2.id);
                }
              }
            });
            arr3.push(arr4);
          });
          this.areas = arr3;
        } else if (result.apply_to_area_type == 3) {
          let arr5 = [],
            arr6 = [];
          result.areaOrorgan.map(item => {
            arr6 = [];
            arr6.push(item.id);
            arr5.push(arr6);
          });
          this.yq = arr5;
        }

        this.scale_id = (typeof(result.scale_id) == 'number')?[result.scale_id]:result.scale_id;
        this.scale_industry_id = (typeof(result.scale_industry_id) == 'number')?[result.scale_industry_id]:result.scale_industry_id;
        this.scale_engage_number = result.scale_engage_number;
        this.scale_revenue = result.scale_revenue;
        this.total_assets = result.total_assets;
        result.policy_types.forEach((item, index) => {
          this.types.push(item.id);
        });
        this.content = result.content;
        this.article_link_url = result.article_link_url;
        if (result.article_link_url == null) {
          this.booksType = "自定义";
          this.changeArtical();
        }
        if (result.content == "") {
          this.booksType = "插入链接";
          this.changeArtical();
        }

        this.img = result.img;
        this.industry_scope = (typeof(result.industry_scope) == 'number')?[result.industry_scope]:result.industry_scope;
        this.industryScopeChange();

        let arr = [],arr2 = [];
        result.policy_start_industries.map(item => {
          arr2 = [];
          item.map(item2 => {
            if (item2.length) {
              arr2.push(item2.id);
            } else {
              let len = arr2.indexOf(arr2.id);
              if (len > -1) {
                arr2.splice(len, 1);
              } else {
                arr2.push(item2.id);
              }
            }
          });
          arr.push(arr2);
        });
        this.industry_scope_array_ct = arr; // 创投行业

        let arr1 = [],arr3 = [];
        result.policy_economic_industries.map(item => {
          arr3 = [];
          item.map(item2 => {
            if (item2.length) {
              arr3.push(item2.id);
            } else {
              let len = arr3.indexOf(arr3.id);
              if (len > -1) {
                arr3.splice(len, 1);
              } else {
                arr3.push(item2.id);
              }
            }
          });
          arr1.push(arr3);
        });
        this.industry_scope_array_gm = arr1; // 国民经济行业       

        result.systems.forEach((item, index) => {
          this.systems.push(item.id);
        });
        this.policy_status = result.policy_status;
        this.policy_date = [
          new Date(result.policy_start_date),
          new Date(result.policy_end_date)
        ];
        this.policy_date_long = new Date(result.policy_start_date)
        result.policy_end_date == ''?(this.policy_checked = true):(this.policy_checked =false)
        result.attachments.forEach((item, index) => {
          this.fileList.push({
            name: item.name,
            url: item.url,
            ext: item.ext
          });
        });
      });
    },
    //获取地区
    getgetArea() {
      this.$http.get(this.URL.adminUrl.areaTree).then(res => {
        localStorage.setItem("area", JSON.stringify(res.data.data));
      });
    },
    //获取园区
    getgetOrgins() {
      this.$http.get(this.URL.adminUrl.search_organs).then(res => {
        this.yuanqu = res.data.data;
      });
    },
    diyuChange() {
      this.apply_to_area_type_array = [];
    },
    //获取规模数据
    getGuimoData() {
      this.$http.get(this.URL.adminUrl.policy).then(res => {
        this.guimo = res.data.data;
      });
    },
    //获取行业数据
    getHangyeData() {
      this.$http.get(this.URL.adminUrl.industry).then(res => {
        for(let i in res.data.data){
          this.allScaleId.push(res.data.data[i].id);
        }
        let hy = res.data.data
        hy.unshift({id: 99,title: '全部'})
        this.hangye = hy
      });
    },
    //获取从业人数数据
    getCongyeData() {
      this.$http.get(this.URL.adminUrl.engages).then(res => {
        this.congye = res.data.data;
      });
    },
    //获取营业收入数据
    getyingyeData() {
      this.$http.get(this.URL.adminUrl.revenues).then(res => {
        this.yingye = res.data.data;
      });
    },
    //获取营业收入数据
    getZichanData() {
      this.$http.get(this.URL.adminUrl.funds).then(res => {
        this.zichan = res.data.data;
      });
    },
    //获取领域数据
    getLingyuData() {
      this.$http.get(this.URL.adminUrl.starts).then(res => {
        this.lingyuCT = res.data.data;
      });
      this.$http.get(this.URL.adminUrl.economics).then(res => {
        this.lingyuGM = res.data.data;
      });
    },
    //获取体系数据
    getTixiData() {
      this.$http.get(this.URL.adminUrl.systems).then(res => {
        this.tixi = res.data.data;
      });
    },
    getMaterialTypeData() {
      this.$http.get(this.URL.adminUrl.types).then(res => {
        this.materialType = res.data.data;
      });
    },
    //获取类型数据
    industryScopeChange() {
      this.lingyuCT_Dis = true
      this.lingyuGM_Dis = true
      if (this.industry_scope.indexOf(1)!=-1 && (this.industry_scope.length === 1)) {
        this.lingyuCT_Dis = false
      } else if(this.industry_scope.indexOf(2)!=-1 && (this.industry_scope.length === 1)){
        this.lingyuGM_Dis = false
      } else if(this.industry_scope.length === 2){
        this.lingyuCT_Dis = false
        this.lingyuGM_Dis = false
      }
    },

    /* 自定义类型 */
    customType() {
      this.$prompt("类型", {
        showClose: false
      })
        .then(({ value }) => {
          var data = {
            title: value,
            organ_id: sessionStorage.organizationId
          };
          this.$http.post(this.URL.adminUrl.type, data).then(res => {
            this.materialType.push(res.data.data);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消自定义"
          });
        });
    },
    /* 自定义体系 */
    customSystems() {
      this.$prompt("体系", {
        showClose: false
      })
        .then(({ value }) => {
          var data = {
            title: value,
            organ_id: sessionStorage.organizationId
          };
          this.$http.post(this.URL.adminUrl.system, data).then(res => {
            this.tixi.push(res.data.data);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消自定义"
          });
        });
    },
    changeArtical() {
      if (this.booksType == "自定义") {
        this.article_link_url = "";
        this.booksTypeActive = false;
      } else {
        this.content = "";
        this.booksTypeActive = true;
      }
    },
    /* 拿到富文本内容  */
    getEditorContent(data) {
      this.content = data;
    },
    // 上传主图之前
    handleChange(file) {
      if (file.size > 1024 * 1024 * 4) {
        this.$message.error("图片不能大于4M");
        return;
      }
    },
    //主图上传成功
    handleAvatarSuccess(res, file) {
      this.img = res.data.path;
    },
    //移除附件
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    //附件改变
    handleOnChange(file, fileList) {
      let obj = {
        name: fileList.name,
        url: fileList.response.data.path,
        ext: fileList.response.data.path.split('.')[fileList.response.data.path.split('.').length-1]
      }
      // this.attachments = [];
      this.fileList.push(obj)
    },
    changeTime(time) {
      if (typeof time === "undefined") {
        return "";
      } else {
        var d = new Date(time);
        var datetime =
          d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        return datetime;
      }
    },
    fabu() {
      this.attachments = this.fileList
      if (this.title == "") {
        this.$message.error("标题不能为空！");
        return;
      }

      if (this.description == "") {
        this.$message.error("描述不能为空！");
        return;
      }

      if (this.publisher_full_name == "" && this.publisher_simple_name == "") {
        this.$message.error("全称和简称必须填其一！");
        return;
      }

      if (this.apply_to_area_type == "1") {
        this.apply_to_area_type_array = [];
      } else if (this.apply_to_area_type == "2") {
        this.apply_to_area_type_array = [];
        if (this.areas.length == 0) {
          this.$message.error("其他省市不能为空！");
          return;
        }
        this.areas.forEach((item, index) => {
          this.apply_to_area_type_array.push(item[item.length - 1]);
        });
      } else {
        this.apply_to_area_type_array = [];
        if (this.yq.length == 0) {
          this.$message.error("园区不能为空！");
          return;
        }
        this.yq.forEach((item, index) => {
          this.apply_to_area_type_array.push(item[item.length - 1]);
        });
      }

      if (this.scale_id == "") {
        this.$message.error("规模编号不能为空！");
        return;
      }
      if (this.scale_industry_id == "") {
        this.$message.error("行业编号不能为空！");
        return;
      }
      if (this.scale_engage_number == "") {
        this.$message.error("从业人数不能为空！");
        return;
      }
      if (this.scale_revenue == "") {
        this.$message.error("营业收入不能为空！");
        return;
      }
      if (this.total_assets == "") {
        this.$message.error("资产总额不能为空！");
        return;
      }
      if (this.types.length == 0) {
        this.$message.error("类型不能为空！");
        return;
      }
      if (this.article_link_url == "" && this.content == "") {
        this.$message.error("文章类型不能为空！");
        return;
      }
      if (this.img == "") {
        this.$message.error("主图不能为空！");
        return;
      }
      let industryScopeArrayCT = [];
      let industryScopeArrayGM = [];

      if (this.industry_scope_array_gm.length == 0 && this.industry_scope_array_ct.length == 0) {
        this.$message.error("领域(行业)不能为空！");
        return;
      }
      this.industry_scope_array_ct.forEach((item, index) => {
        industryScopeArrayCT.push(item[item.length - 1]);
      });
      this.industry_scope_array_gm.forEach((item, index) => {
        industryScopeArrayGM.push(item[item.length - 1]);
      });
      if (this.industry_scope.indexOf(1)!=-1 && (this.industry_scope.length === 1)) { //只有创投
        industryScopeArrayGM = []
      } else if(this.industry_scope.indexOf(2)!=-1 && (this.industry_scope.length === 1)){ //只有国民
        industryScopeArrayCT = []
      } else if(this.industry_scope.length === 0){ 
        industryScopeArrayCT = [];
        industryScopeArrayGM = [];
      }

      if (this.systems.length == 0) {
        this.$message.error("体系不能为空！");
        return;
      }

      let id = sessionStorage.organizationId;
      var data = {
        organ_id: id,
        title: this.title, //标题
        description: this.description, //描述
        publisher_full_name: this.publisher_full_name, //全称
        publisher_simple_name: this.publisher_simple_name, //简称
        apply_to_area_type: this.apply_to_area_type, //地域:1全国,2其他省市,3园区
        apply_to_area_type_array: this.apply_to_area_type_array, //2省市区数组，3:园区数组，1:不传
        scale_id: this.scale_id, //规模编号
        scale_industry_id: this.scale_industry_id, //行业编号
        scale_engage_number: this.scale_engage_number, //从业人数
        scale_revenue: this.scale_revenue, //	营业收入
        total_assets: this.total_assets, //资产总额
        types: this.types, //类型
        article_link_url: this.article_link_url, //文章类型链接地址，为空则表示本地输入，否则为外链
        content: this.content, //富文本
        img: this.img, //主图
        industry_scope: this.industry_scope, //1创投2国民经济
        industry_start_array: industryScopeArrayCT, //1:创投数组
        industry_economic_array: industryScopeArrayGM, //2:国民经济数组
        systems: this.systems, //体系
        policy_status: this.policy_status, //政策状态 1即将生效2生效中3已过期4延长期
        policy_start_date: this.policy_checked?this.changeTime(this.policy_date_long):this.changeTime(this.policy_date[0]), //	政策开始时间
        policy_end_date: this.policy_checked?'':this.changeTime(this.policy_date[1]), //政策结束时间
        attachments: this.attachments, //附件
        demands: this.demands //需求标签
      };
      if (typeof this.id != "undefined") {
        this.$http
          .put(this.URL.adminUrl.delPolicyArticle + this.id, data)
          .then(res => {
            this.$alert("修改成功", {
              callback: () => {
                this.$router.push("/policyCompilation");
              }
            });
          });
      } else {
        this.$http
          .post(this.URL.adminUrl.addMaterial, data)
          .then(res => {
            this.$alert("上传成功", {
              callback: () => {
                this.$router.push("/policyCompilation");
              }
            });
          });
        }
      },
    //需求标签列表(添加政策时用)
    async categoryDemands() {
      const { data: res } = await this.$http.get(
        this.URL.adminUrl.categoryDemands
      );
      this.demandsLable = res.data;
      this.threeLable = this.demandsLable.slice(0, 3);

    },
    //自定义添加需求标签
    orderDemands() {
      this.orderDialog = true;
      this.orderLabel = "";
    },
    //确定标签
    orderSure() {
      if (this.orderLabel.trim() == "") {
        warning("输入标签不能为空");
      } else if (this.orderLabel.length > 10) {
        warning("标签最多输入10个字符");
      } else {
        this.$http
          .post(this.URL.adminUrl.orderDemands, { title: this.orderLabel })
          .then(res => {
            this.orderDialog = false;
            success("自定义标签添加成功");
            this.categoryDemands();

          });
      }
    },
    // 行业全选
    hyChange(val) {
      val.indexOf(99) == -1?this.hangye:(this.scale_industry_id = this.allScaleId)
    }
  }
};
</script>

<style scoped>
.policyCompilationTitle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 18px;
  height: 40px;
}

.navbar {
  margin-bottom: 30px;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.label {
  width: 135px;
  text-align: right;
  padding-right: 8px;
  font-size: 14px;
}

.itemBorder {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
}

.controls {
  display: flex;
  align-items: center;
  margin: 8px 0;
  padding-right: 15px;
}

.formControl {
  width: 100%;
}

.controlslabel {
  width: 75px;
  text-align: right;
  font-size: 14px;
}

.guimo .el-input__inner {
  width: 240px !important;
}

.avatar-uploader {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: 14px;
}

.noAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100px;
  height: 100px;
}

.avatar {
  width: 100px;
  height: 100px;
  vertical-align: middle;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
}

.avatar-uploader:hover {
  color: #45d298;
  border: 1px solid #45d298;
  background-color: #ffffff;
}

.avatar-uploader:hover .avatar-uploader-icon {
  color: #45d298;
}

.required:before {
  content: "*";
  color: red;
  font-size: 16px;
  margin-right: 2px;
}

.el-upload__tip {
  color: red;
}

.btn-primary {
  background-color:#528cfe;
  color: #ffffff;
  border-radius: 19px;
}

.yinyeshouru:after {
  content: "万元";
  position: absolute;
  width: 40px;
  line-height: 28px;
  padding-left: 5px;
}

.fijian-icon-upload {
  color: #409eff;
  font-size: 67px;
  margin: 40px 0 16px;
  line-height: 50px;
}

.el-input-group__append {
  padding: 0 5px !important;
}

.el-cascader--mini {
  width: 100%;
}
.lyClass>div:nth-child(2){
  width: 150px;
}
.lyClass>div:nth-child(2)>div>.el-checkbox:first-child{
  margin-bottom: 15px;
}
.lyClass .lyClass-select>div:first-child{
  margin-bottom: 5px;
}
</style>
